import React from 'react';

import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import TournamentDirectors from 'src/components/tournament-directors/tournament-directors';
import { usePrivateRoute } from 'src/utils/auth';

const TournamentDirectorsPage = () => {
  usePrivateRoute();
  return (
    <Layout>
      <SEO title="Tournament Directors" />
      <TournamentDirectors />
    </Layout>
  );
};

export default TournamentDirectorsPage;
