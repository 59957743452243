import React, { useCallback, useMemo, useState } from 'react';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import { useTranslation } from 'react-i18next';
import useFilters from 'src/hooks/use-filters';

import Button from '../button/button';
import FilterSet, { Filter } from '../filter-set/filter-set';
import TextInput from '../input/input';

export interface TournamentDirectorsFilters {
  firstName: string;
  lastName: string;
}

interface Props {
  onFilterChange?: (filter: TournamentDirectorsFilters) => any;
  initFilter: TournamentDirectorsFilters;
}

const TournamentDirectorFilters: React.FC<Props> = ({ onFilterChange, initFilter }) => {
  const [_, setFilters] = useFilters({
    initialFilter: initFilter,
    onChange: onFilterChange,
  });
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const { t } = useTranslation();

  const handleSearch = useCallback(() => {
    setFilters({ firstName, lastName });
  }, [firstName, lastName]);

  return (
    <FilterSet spacing={{ margins: { lg: 'bottom' } }}>
      <Filter>
        <TextInput
          placeholder={t('first name')}
          onChange={(t) => setFirstName(t.target.value)}
          spacing={{ margins: { xs: 'right' } }}
          disableUnderline
          outlined
        />
      </Filter>
      <Filter>
        <TextInput
          placeholder={t('last name')}
          onChange={(t) => setLastName(t.target.value)}
          spacing={{ margins: { xs: 'right' } }}
          disableUnderline
          outlined
        />
      </Filter>
      <Button onClick={handleSearch}>Search</Button>
    </FilterSet>
  );
};

export default TournamentDirectorFilters;
